import React, { useState, useEffect } from "react";
import { StaticImage } from "gatsby-plugin-image";
import Layout from "../../components/layout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
const IndexPage = () => {
  const [button, setButton] = useState(false);
  function openModal() {
    setButton(true);
    setTimeout(() => {
      setButton(false);
    }, 3000);
  }
  return (
    <Layout location={"/shared/homePage/"}>
      <title>Ole Lillebo</title>
      <div className="bg-gray-50 dark:bg-gray-900 dark:text-[#94a3b8] print:bg-white pt-16 -mt-16 h-screen max-h-screen">
        {/*
        <div className="flex flex-col py-6 justify-center items-center ">
          <div
            onClick={() => openModal()}
            className={classNames(
              button
                ? "!p-0 border-4 border-dashed border-gray-50 dark:border-gray-900 animate-spin2"
                : "",
              "group mb-2 flex flex-row p-1 justify-center w-fit bg-gradient-to-br from-[#49b6ff] to-interaction rounded-full"
            )}
          >
            <div className=" flex flex-row justify-center  rounded-full">
              <div className="flex items-center justify-center">
                 <StaticImage
                  src="../images/ole2.png"
                  alt="Profile picture"
                  layout="fixed"
                  width={150}
                  height={150}
                  className={classNames(
                    button ? "animate-spinReverse" : "",
                    "rounded-full border-4 border-gray-50 dark:border-gray-900 "
                  )}
                /> 
              </div>
            </div>
          </div>
          <div className="flex items-center text-4xl mb-2 font-extrabold font-Caveat text-gray-900 justify-center">
            Ole Lillebo
          </div>
        </div>*/}
      </div>
    </Layout>
  );
};

export default IndexPage;
